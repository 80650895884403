/* You can add global styles to this file, and also import other style files */


/* @import '@angular/material/prebuilt-themes/deeppurple-amber.css'; */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ag-grid-community/styles/ag-grid.css";   
@import "~ag-grid-community/styles/ag-theme-balham.css";
@import 'assets/css/buttons.css';
@import 'assets/css/material-fields.css';
@import 'assets/css/modals.css';
@import 'assets/css/div.css';
@import 'assets/css/grid.css';
@import 'assets/css/cui-standards.min.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import 'assets/css/quill-editor.css';
@import 'assets/css/highcharts.css';
@import 'tippy.js/dist/tippy.css';

html {
	height: 100% !important;
	font-size: 62.5% !important;
}

body {
	color: #58585b !important;
	font-family: CiscoSans, Arial, sans-serif !important;
	line-height: 2rem !important;
	font-weight: 400 !important;
	font-size: 1.4rem !important;
}
