.btn-primary {
    background-color: #00bceb !important;
    border-color: #00bceb !important;
    border-radius: 2rem !important;
    color: #212529 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 1.4rem !important;
    font-weight: normal !important;
    /* float: right !important; */
    font-weight: 400 !important;
    font-family: CiscoSans,Arial,sans-serif !important;
}

.btn-dark {
    background-color: #475057 !important;
    border-color: #475057 !important;
    border-radius: 2rem !important;
    color: #ffffff !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 1.4rem !important;
    font-weight: normal !important;
    /* float: right !important; */
    font-weight: 400 !important;
    font-family: CiscoSans,Arial,sans-serif !important;
}

.btn-secondary {
    background-color: #1e4471 !important;
    border-color: #1e4471 !important;
    border-radius: 2rem !important;
    color: #ffffff !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 1.4rem !important;
    font-weight: normal !important;
    /* float: right !important; */
    font-weight: 400 !important;
    font-family: CiscoSans,Arial,sans-serif !important;
}

.btn-danger {
    background-color: #e2231a !important;
    border-color: #e2231a !important;
    border-radius: 2rem !important;
    color: #ffffff !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 1.4rem !important;
    font-weight: normal !important;
    /* float: right !important; */
    font-weight: 400 !important;
    font-family: CiscoSans,Arial,sans-serif !important;
}

.btn-action {
    background-color: #017cad !important;
    border-color: #017cad !important;
    border-radius: 2rem !important;
    color: #ffffff !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 1.4rem !important;
    font-weight: normal !important;
    /* float: right !important; */
    font-weight: 400 !important;
    font-family: CiscoSans,Arial,sans-serif !important;
}

.btn:disabled {
    background: transparent !important;
    border: 1px dotted #495057 !important;
    border-radius: 2rem !important;
    color: #495057 !important;
    cursor: not-allowed !important;
    pointer-events: auto !important;
    font-size: 1.4rem !important;
    font-weight: normal !important;
    /* float: right !important; */
    font-weight: 400 !important;
    font-family: CiscoSans,Arial,sans-serif !important;
}

.btn-group {
    position: relative !important;
    display: inline-block !important;
    vertical-align: middle !important;
}

.btn-default {
    background-color: #ffffff !important;
    border-color: #017cad !important;
    color: #017cad !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 13.5px !important;
    font-weight: normal !important;
    float: left !important;
    position: relative !important;
    font-weight: 400 !important;
    font-family: CiscoSans,Arial,sans-serif !important;
}

.btn-default:disabled {
    background-color: transparent !important;
    border: 1px dotted #495057 !important;
    border-radius: 0% !important;
    border-color: #495057 !important;
    color: #495057 !important;
    cursor: not-allowed !important;
    pointer-events: auto !important;
    font-size: 1.4rem !important;
    font-weight: normal !important;
    float: left !important;
    position: relative !important;
    font-weight: 400 !important;
    font-family: CiscoSans,Arial,sans-serif !important;
}

.btn-default.selected, .btn-default.hover, .btn-default:hover {
    background-color: #017cad !important;
    border-color: #017cad !important;
    color: #ffffff !important;
    outline: none;
}

.btn-pull-left, .btn-default:disabled.btn-pull-left{
    border-top-left-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
}
  
.btn-pull-right, .btn-default:disabled.btn-pull-right{
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;
}

.btn:focus {
    box-shadow: none !important;
}

.primary {
    background-color: #00bceb !important;
}

.secondary {
    background-color: #1e4471 !important;
}

.danger {
    background-color: #e2231a !important;
}

.success {
    background-color: #6abf4b !important;
}

.info {
    background-color: #0175a2 !important;
}

.action {
    background-color: #495057 !important;
}

.warning {
    background-color: #fbab18 !important;
}

.white {
    background-color: #ffffff !important;
}

.mat-mdc-mini-fab:disabled {
    border: 1px dotted #495057 !important;
    pointer-events: auto !important;
    cursor: not-allowed !important;
    background: transparent !important;
}

.mat-mdc-mini-fab:disabled .material-icons{
    color: #495057 !important;
}

.mat-mdc-mini-fab {
    width: 35px !important;
    height: 35px !important;
    cursor: pointer !important;
    pointer-events: auto !important;
}

/* .mat-mdc-mini-fab .mdc-button__label {
    padding: 5px 0 !important;
} */

.mat-mdc-raised-button {
    line-height: 36px !important;
    letter-spacing: normal !important;
}

.tippy-box {
    background-color: #fff !important;
}

.tippy-content {
    padding: 0% 0% !important;
}

.tippy-box[data-placement^=top]>.tippy-arrow {
    display: none !important;
}