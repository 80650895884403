tspan {
    stroke: none !important;
}

.highcharts-xaxis-labels text,
.highcharts-yaxis-labels text {
    font-size: 11px !important;
}

.highcharts-data-label text {
    font-size: 9.5px !important;
    font-weight: bold !important;
}

.highcharts-legend-item text{
    font-size: 12px !important;
    font-weight: bold !important;
}

.highcharts-yaxis text,
.highcharts-xaxis text {
    font-size: 11px !important;
}

.highcharts-no-data text {
    font-size: 12px !important;
}