.mat-drawer.mat-drawer-side {
    z-index: 0 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #017cad !important;
}

.mat-tab-header {
    padding-left: 0% !important;
    padding-right: 0% !important;
}

mat-tab-group .mat-tab-header .mat-tab-list .material-icons {
    font-size: medium !important;
}

.mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: #017cad;
    --mat-tab-header-active-label-text-color: #017cad;
    --mat-tab-header-active-ripple-color: #017cad;
    --mat-tab-header-inactive-ripple-color: #017cad;
    --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-focus-label-text-color: #017cad;
    --mat-tab-header-active-hover-label-text-color: #017cad;
    --mat-tab-header-active-focus-indicator-color: #017cad;
    --mat-tab-header-active-hover-indicator-color: #017cad;
    border-bottom: 1px solid rgb(87 64 64 / 12%) !important;
}

.mat-mdc-tab-link {
    color: #017cad !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: normal !important;
}

.mat-mdc-tab-link.mdc-tab-indicator--active {
    min-width: fit-content !important;
    padding: 0 10px !important;
    color: #017cad !important;
    font-size: 1.4rem !important;
    font-weight: 400 !important;
    font-family: CiscoSans, Arial, sans-serif !important;
    opacity: 1.5 !important;
}

.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
    flex-grow: initial !important;
}

.mat-mdc-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: transparent !important;
}

.material-icons {
    cursor: pointer !important;
}

.mat-mdc-select-panel {
    background: #ffffff !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
    color: #000000 !important;
}

.mdc-list-item__primary-text:hover {
    color: #017CAD !important;
}

.mat-mdc-option {
    line-height: 3em !important;
    height: 3em !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    min-height: 40px !important;
}

.mat-pseudo-checkbox {
    color: #017CAD !important;
}

.mat-mdc-select-panel[aria-multiselectable="false"] mat-pseudo-checkbox {
    display: none !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #017CAD !important;
}

.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
    background-color: none !important;
}

mat-list-option:hover {
    cursor: pointer !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: #017cad !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: #017cad !important;
}

.custom-loaderClass .mat-dialog-container {
    box-shadow: none !important;
    background: transparent !important;
}

button.mat-icon-button:focus {
    outline: none !important;
}

/* .mat-input-element:disabled {
    color: #58585b !important;
}

.mat-select-disabled .mat-select-value {
    color: #58585b !important;
} */

.mat-checkbox-frame {
    border-color: rgba(0, 0, 0, .38) !important;
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content,
.mat-checkbox-disabled .mat-checkbox-label {
    color: rgba(0, 0, 0, .58) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #017cad !important;
}

.mat-table {
    width: 100%;
}

.mat-mdc-mini-fab.mat-accent {
    color: #ffffff !important;
}

.mat-mdc-mini-fab:not([class*=mat-elevation-z]) {
    box-shadow: none !important;
}

.mat-button-focus-overlay {
    background-color: transparent !important;
}

.mat-menu-item:hover {
    color: #017CAD !important;
}

.mat-menu-panel {
    font-weight: normal !important;
    font-size: 20px !important;
    overflow: hidden !important;
}

.hide-unmatched-mat-option {
    display: none !important;
}

.cisco-tooltip {
    background-color: rgba(88, 88, 91, .99) !important;
    font-size: 1.2rem !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.mat-calendar-body-selected {
    background-color: #017cad !important;
    color: #fff !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgb(1 124 173 / 68%) !important;
}

.mat-datepicker-toggle-active {
    color: #017cad !important;
}

.mat-select-overlay-pane {
    transform: translateX(0px) translateY(0px) !important;
    width: fit-content !important;
}

.mat-select-overlay-pane .mat-select-panel-wrap {
    margin-top: 0% !important;
}

.mat-select-overlay-pane .mat-select-panel:not([class*=mat-elevation-z]) {
    min-width: 99% !important;
}

.mat-mdc-chip .mat-mdc-chip-action-label {
    font-size: 12px !important;
    font-weight: normal !important;
}

.mat-menu-item-selected {
    color: #017CAD !important;
    background: rgba(0, 0, 0, .04) !important;
}

.mat-mdc-text-field-wrapper {
    height: 35px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 17px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-floating-label--float-above {
    top: 30px !important;
}

.mdc-text-field--outlined .mdc-floating-label {
    font-family: CiscoSans, Arial, sans-serif !important;
    font-size: 1.4rem !important;
    font-weight: 400 !important;
    letter-spacing: normal !important;
    color: rgba(0, 0, 0, .6) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch {
    border-color: #00bceb !important;
}

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__trailing,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__notch {
    border-width: 1px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, .12) !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-left: none !important;
}

.mat-mdc-form-field-type-mat-input.mat-focused .mdc-text-field--outlined .mat-mdc-form-field-infix,
.mat-mdc-form-field-type-mat-input.mat-focused .mdc-text-field--no-label .mat-mdc-form-field-infix,
.mat-mdc-form-field-type-mat-input.ng-touched .mdc-text-field--outlined .mat-mdc-form-field-infix,
.mat-mdc-form-field-type-mat-input.ng-touched .mdc-text-field--no-label .mat-mdc-form-field-infix,
.mat-mdc-form-field-type-mat-input.ng-pristine .mdc-text-field--outlined .mat-mdc-form-field-infix,
.mat-mdc-form-field-type-mat-input.ng-pristine .mdc-text-field--no-label .mat-mdc-form-field-infix,
.mat-mdc-form-field-type-mat-input .mat-mdc-form-field-infix {
    padding-top: 8px !important;
}

.mdc-text-field--outlined .mdc-text-field__input {
    border: none !important;
    background-color: rgba(0, 0, 0, 0);
    font-size: 14.5px !important;
    font-weight: 400 !important;
    line-height: 1.125 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: CiscoSans, Arial, sans-serif !important;
}

.mat-form-field-disabled .mdc-text-field__input,
.mat-form-field-disabled .mat-mdc-select {
    color: rgba(0, 0, 0, 0.65) !important;
}

/* .mat-mdc-form-field-type-mat-input.mdc-text-field--disabled .mdc-text-field--outlined .mat-mdc-form-field-infix,
.mat-mdc-form-field-type-mat-input.mdc-text-field--disabled .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 0.5%;
} */

.mat-mdc-form-field-type-mat-select .mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 5px !important;
}

.mat-mdc-form-field-required-marker {
    color: red !important;
}

label {
    letter-spacing: normal !important;
}

input.search {
    height: 40px !important;
    border: none !important;
    border-bottom: 1px solid #f2f2f2 !important;
    text-indent: 4% !important;
    width: 100% !important;
}

input.search:focus-visible {
    outline: none !important;
}

.selectAll .mat-pseudo-checkbox {
    display: none !important;
}

.selectAll .mdc-checkbox {
    margin-left: -10px !important;
}


.selectAll .mdc-form-field>label {
    font-weight: 500 !important;
    padding-left: 17px !important;
    font-size: 16px !important;
}

.mat-mdc-select-value {
    font-size: 14px !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
    background-color: transparent !important;
}

.mat-mdc-radio-button .mdc-label {
    margin-bottom: 0% !important;
}

.mat-mdc-checkbox .mdc-label {
    margin-bottom: 0% !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate~.mdc-checkbox__background {
    border-color: #017cad !important;
    background-color: #017CAD !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #017cad !important;
    background-color: #017CAD !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    color: #ffffff !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus~.mdc-checkbox__ripple {
    background-color: transparent !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    background-color: transparent !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
    caret-color: #017cad !important;
}

.ng-touched .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.ng-touched .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.ng-touched .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: red !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
    color: #017cad !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
    background-color: transparent !important;
}

.mdc-checkbox {
    flex: 0 0 7% !important;
}

.mdc-checkbox:hover .mdc-checkbox__ripple {
    background-color: transparent !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
    padding-top: 0% !important;
}

textarea {
    resize: none !important;
}

.text-area .mat-mdc-text-field-wrapper {
    height: auto !important;
}

html .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: #00a3d2;
    --mdc-switch-selected-handle-color: #00a3d2;
    --mdc-switch-selected-hover-state-layer-color: #00a3d2;
    --mdc-switch-selected-pressed-state-layer-color: #00a3d2;
    --mdc-switch-selected-focus-handle-color: #00bceb;
    --mdc-switch-selected-hover-handle-color: #00bceb;
    --mdc-switch-selected-pressed-handle-color: #00bceb;
    --mdc-switch-selected-focus-track-color: #1ad6ff;
    --mdc-switch-selected-hover-track-color: #1ad6ff;
    --mdc-switch-selected-pressed-track-color: #1ad6ff;
    --mdc-switch-selected-track-color: #1ad6ff;
}

.mat-mdc-paginator-page-size-select .mdc-notched-outline__trailing,
.mat-mdc-paginator-page-size-select .mdc-notched-outline__notch,
.mat-mdc-paginator-page-size-select .mdc-notched-outline__leading {
    border: none !important;
}

.mat-mdc-paginator-page-size-select .mdc-notched-outline__trailing {
    border-bottom: solid 1px !important;
    border-radius: 0 !important;
}

.mat-mdc-form-field-hint {
    font-size: 11px !important;
}

.mat-mdc-form-field-hint-spacer {
    flex: none !important;
}

.mat-mdc-form-field-hint-wrapper {
    padding: 0 0 !important;
}

/* .mat-mdc-select-disabled .mat-mdc-select-value-text {
    color: #58585b !important;
} */

.mdc-button {
    line-height: inherit !important;
}

.mat-mdc-paginator-range-actions .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0px 12px !important;
}

.mat-mdc-paginator-range-label {
    margin: 0px 2px !important;
}

.mat-mdc-paginator-page-size-select {
    margin: 0px !important;
}

.mat-mdc-paginator-page-size {
    margin-right: 0px !important;
}

.mat-mdc-paginator .mat-mdc-select-value {
    font-size: 12px !important;
}

/* .mdc-list-item.mdc-list-item--disabled,
.mdc-list-item.mdc-list-item--non-interactive {
    cursor: not-allowed !important;
} */


.mdc-list-item.mdc-list-item--disabled .mat-mdc-list-base .mdc-list-item__start,
.mdc-list-item.mdc-list-item--disabled .mat-mdc-list-base .mdc-list-item__end,
.mdc-list-item.mdc-list-item--disabled .mat-mdc-list-base .mdc-list-item__content {
    pointer-events: none !important;
}