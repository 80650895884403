@charset "UTF-8";

/*! 
 * cisco-ui - Cisco UI Kit
 * @version 1.3.3-official
 * @home https://www.cisco.com
 * @license 2018 Cisco Systems, Inc. All rights reserved. Terms and Conditions: http://cisco.com/en/US/swassets/sw293/sitewide_important_notices.html
 */

 :root {
	--bs-font-sans-sarif: 'CiscoSans', 'Arial', sans-serif !important;
	--bs-font-monospace: Monaco, Inconsolata, Consolas, 'Courier New', Courier, 'Lucida Console', monospace !important;
	--bs-font-weight: normal !important;
	--bs-header-height: 50px !important;
	--bs-item-height: 34px !important;
	--bs-line-height: 1.5 !important;
	/* Colors */
	--bs-white: #ffffff !important;
	--bs-gray-100: #f8f8f8 !important;
	--bs-gray-200: #f2f2f2 !important;
	--bs-gray-300: #dee2e6 !important;
	--bs-gray-400: #ced4da !important;
	--bs-gray-500: #adb5bd !important;
	--bs-gray-600: #6c757d !important;
	--bs-gray-700: #495057 !important;
	--bs-gray-800: #343a40 !important;
	--bs-gray-900: #212529!important;
	--bs-black: #000000 !important;
	--bs-danger: #e2231a !important;
	--bs-info: #64bbe3 !important;
	--bs-midnight: #0d274d !important;
	--bs-ocean: #1e4471 !important;
	--bs-sky: #00bceb !important;
	--bs-sky-rgb: 0, 188, 235 !important;
	--bs-success: #6abf4b !important;
	--bs-warning: #fbab18 !important;
	--bs-warning-alt: #eed202 !important;
	--bs-link: #0175a2 !important;
}
 
@font-face {
	font-family: 'CiscoSans';
	font-style: normal;
	font-weight: 100;
	src: url("../fonts/CiscoSans/CiscoSansTTThin.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTThin.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: oblique;
	font-weight: 100;
	src: url("../fonts/CiscoSans/CiscoSansTTThinOblique.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTThinOblique.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: normal;
	font-weight: 200;
	src: url("../fonts/CiscoSans/CiscoSansTTExtraLight.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTExtraLight.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: oblique;
	font-weight: 200;
	src: url("../fonts/CiscoSans/CiscoSansTTExtraLightOblique.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTExtraLightOblique.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: normal;
	font-weight: 300;
	src: url("../fonts/CiscoSans/CiscoSansTTLight.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTLight.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: oblique;
	font-weight: 300;
	src: url("../fonts/CiscoSans/CiscoSansTTLightOblique.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTLightOblique.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/CiscoSans/CiscoSansTTRegular.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTRegular.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: oblique;
	font-weight: 400;
	src: url("../fonts/CiscoSans/CiscoSansTTRegularOblique.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTRegularOblique.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: normal;
	font-weight: 600;
	src: url("../fonts/CiscoSans/CiscoSansTTMedium.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTMedium.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: oblique;
	font-weight: 600;
	src: url("../fonts/CiscoSans/CiscoSansTTMediumOblique.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTMediumOblique.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: bold;
	font-weight: 700;
	src: url("../fonts/CiscoSans/CiscoSansTTBold.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTBold.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: oblique;
	font-weight: 700;
	src: url("../fonts/CiscoSans/CiscoSansTTBoldOblique.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTBoldOblique.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: normal;
	font-weight: 900;
	src: url("../fonts/CiscoSans/CiscoSansTTHeavy.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTHeavy.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: 'CiscoSans';
	font-style: oblique;
	font-weight: 900;
	src: url("../fonts/CiscoSans/CiscoSansTTHeavyOblique.woff2") format("woff2"), url("../fonts/CiscoSans/CiscoSansTTHeavyOblique.woff") format("woff");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: cui-font;
	src: url(../fonts/cui-font.eot);
	src: url(../fonts/cui-font.eot?#iefix) format("eot"), url(../fonts/cui-font.woff2) format("woff2"), url(../fonts/cui-font.woff) format("woff"), url(../fonts/cui-font.ttf) format("truetype"), url(../fonts/cui-font.svg#cui-font) format("svg")
}

*[class*='icon-'] {
	position: relative;
	display: inline-block;
	font-family: "cui-font";
	font-size: 20px;
	font-style: normal;
	font-weight: normal;
	speak: none;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: optimizeLegibility;
	vertical-align: middle;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; 
	cursor: pointer;
}

.icon-account:before {
	content: "";
}

.icon-acessibility:before {
	content: "";
}

.icon-activities:before {
	content: "";
}

.icon-add-contact:before {
	content: "";
}

.icon-add-contain:before {
	content: "";
}

.icon-add-outline:before {
	content: "";
}

.icon-add:before {
	content: "";
}

.icon-admin:before {
	content: "";
}

.icon-all-calls:before {
	content: "";
}

.icon-analysis:before {
	content: "";
}

.icon-android-home:before {
	content: "";
}

.icon-animation:before {
	content: "";
}

.icon-annotation:before {
	content: "";
}

.icon-answer-oldest:before {
	content: "";
}

.icon-applause:before {
	content: "";
}

.icon-application:before {
	content: "";
}

.icon-applications:before {
	content: "";
}

.icon-apps:before {
	content: "";
}

.icon-asterisk:before {
	content: "";
}

.icon-at-contain:before {
	content: "";
}

.icon-at:before {
	content: "";
}

.icon-attachment:before {
	content: "";
}

.icon-backup-data:before {
	content: "";
}

.icon-blocked:before {
	content: "";
}

.icon-bookmark:before {
	content: "";
}

.icon-briefcase:before {
	content: "";
}

.icon-broken-image:before {
	content: "";
}

.icon-browser:before {
	content: "";
}

.icon-bug:before {
	content: "";
}

.icon-call-log:before {
	content: "";
}

.icon-call-rate:before {
	content: "";
}

.icon-callback:before {
	content: "";
}

.icon-camera:before {
	content: "";
}

.icon-cart:before {
	content: "";
}

.icon-chapters:before {
	content: "";
}

.icon-chats:before {
	content: "";
}

.icon-circle:before {
	content: "";
}

.icon-cisco:before {
	content: "";
}

.icon-clipboard-md:before {
	content: "";
}

.icon-clipboard-sm:before {
	content: "";
}

.icon-clipboard:before {
	content: "";
}

.icon-clone:before {
	content: "";
}

.icon-close:before {
	content: "";
}

.icon-cloud-config-16:before {
	content: "";
}

.icon-cloud-config-24:before {
	content: "";
}

.icon-cloud-config-48:before {
	content: "";
}

.icon-cloud:before {
	content: "";
}

.icon-cog:before {
	content: "";
}

.icon-comment:before {
	content: "";
}

.icon-communities:before {
	content: "";
}

.icon-community:before {
	content: "";
}

.icon-compass:before {
	content: "";
}

.icon-compliance:before {
	content: "";
}

.icon-computer-queue:before {
	content: "";
}

.icon-computer:before {
	content: "";
}

.icon-conference:before {
	content: "";
}

.icon-configurations:before {
	content: "";
}

.icon-contact-card:before {
	content: "";
}

.icon-contact:before {
	content: "";
}

.icon-contract-login-md:before {
	content: "";
}

.icon-contract-login-sm:before {
	content: "";
}

.icon-contract-login:before {
	content: "";
}

.icon-create-page:before {
	content: "";
}

.icon-data-usage:before {
	content: "";
}

.icon-default-app:before {
	content: "";
}

.icon-delete:before {
	content: "";
}

.icon-diagnostics:before {
	content: "";
}

.icon-dial:before {
	content: "";
}

.icon-directory:before {
	content: "";
}

.icon-disc:before {
	content: "";
}

.icon-display:before {
	content: "";
}

.icon-document:before {
	content: "";
}

.icon-draggable:before {
	content: "";
}

.icon-email:before {
	content: "";
}

.icon-emoticons:before {
	content: "";
}

.icon-exit-contain:before {
	content: "";
}

.icon-exit-fullscreen:before {
	content: "";
}

.icon-exit-outline:before {
	content: "";
}

.icon-exit:before {
	content: "";
}

.icon-eye-closed:before {
	content: "";
}

.icon-eye:before {
	content: "";
}

.icon-features:before {
	content: "";
}

.icon-feedback-clear:before {
	content: "";
}

.icon-feedback-result:before {
	content: "";
}

.icon-feedback:before {
	content: "";
}

.icon-filter:before {
	content: "";
}

.icon-fullscreen:before {
	content: "";
}

.icon-google-analytics:before {
	content: "";
}

.icon-graph:before {
	content: "";
}

.icon-grid-view:before {
	content: "";
}

.icon-group-chat:before {
	content: "";
}

.icon-help-alt:before {
	content: "";
}

.icon-help-outline:before {
	content: "";
}

.icon-help:before {
	content: "";
}

.icon-home:before {
	content: "";
}

.icon-hue:before {
	content: "";
}

.icon-hunt-group:before {
	content: "";
}

.icon-idefix:before {
	content: "";
}

.icon-inbox:before {
	content: "";
}

.icon-insights:before {
	content: "";
}

.icon-instant-meeting:before {
	content: "";
}

.icon-invite:before {
	content: "";
}

.icon-keyboard:before {
	content: "";
}

.icon-language:before {
	content: "";
}

.icon-layers:before {
	content: "";
}

.icon-layout:before {
	content: "";
}

.icon-learning:before {
	content: "";
}

.icon-lifecycle:before {
	content: "";
}

.icon-lightbulb:before {
	content: "";
}

.icon-like:before {
	content: "";
}

.icon-list-menu:before {
	content: "";
}

.icon-list-view:before {
	content: "";
}

.icon-location:before {
	content: "";
}

.icon-lock-contain:before {
	content: "";
}

.icon-lock:before {
	content: "";
}

.icon-mail-read:before {
	content: "";
}

.icon-mail:before {
	content: "";
}

.icon-meeting-room:before {
	content: "";
}

.icon-message:before {
	content: "";
}

.icon-micro-blog:before {
	content: "";
}

.icon-mobile-phone:before {
	content: "";
}

.icon-mobile-presenter:before {
	content: "";
}

.icon-more:before {
	content: "";
}

.icon-notebook-in:before {
	content: "";
}

.icon-outbox:before {
	content: "";
}

.icon-participant-list:before {
	content: "";
}

.icon-pass-mouse:before {
	content: "";
}

.icon-pause:before {
	content: "";
}

.icon-persistent-chat:before {
	content: "";
}

.icon-pin:before {
	content: "";
}

.icon-playlist:before {
	content: "";
}

.icon-plugin:before {
	content: "";
}

.icon-plus:before {
	content: "";
}

.icon-point:before {
	content: "";
}

.icon-poll:before {
	content: "";
}

.icon-popout:before {
	content: "";
}

.icon-popup-dialogue:before {
	content: "";
}

.icon-prevent-download-contain:before {
	content: "";
}

.icon-prevent-download:before {
	content: "";
}

.icon-print:before {
	content: "";
}

.icon-privacy:before {
	content: "";
}

.icon-private:before {
	content: "";
}

.icon-profile-settings:before {
	content: "";
}

.icon-proximity-not-connected:before {
	content: "";
}

.icon-proximity:before {
	content: "";
}

.icon-quality:before {
	content: "";
}

.icon-question-circle:before {
	content: "";
}

.icon-raise-hand:before {
	content: "";
}

.icon-read-email:before {
	content: "";
}

.icon-recent-apps:before {
	content: "";
}

.icon-refresh:before {
	content: "";
}

.icon-remove-contact:before {
	content: "";
}

.icon-remove-contain:before {
	content: "";
}

.icon-remove-outline:before {
	content: "";
}

.icon-remove:before {
	content: "";
}

.icon-reset:before {
	content: "";
}

.icon-rtprx-rtptx-duplex:before {
	content: "";
}

.icon-rtprx:before {
	content: "";
}

.icon-rtptx:before {
	content: "";
}

.icon-schedule-add:before {
	content: "";
}

.icon-search:before {
	content: "";
}

.icon-send:before {
	content: "";
}

.icon-setup-assistant:before {
	content: "";
}

.icon-shipment:before {
	content: "";
}

.icon-sign-in:before {
	content: "";
}

.icon-sign-out:before {
	content: "";
}

.icon-signal-1:before {
	content: "";
}

.icon-signal-2:before {
	content: "";
}

.icon-signal-3:before {
	content: "";
}

.icon-signal-4:before {
	content: "";
}

.icon-slides:before {
	content: "";
}

.icon-software-certified-md:before {
	content: "";
}

.icon-software-certified-sm:before {
	content: "";
}

.icon-software-certified:before {
	content: "";
}

.icon-software-suggested-md:before {
	content: "";
}

.icon-software-suggested-sm:before {
	content: "";
}

.icon-software-suggested:before {
	content: "";
}

.icon-software:before {
	content: "";
}

.icon-space:before {
	content: "";
}

.icon-speed-dial:before {
	content: "";
}

.icon-square-o-checked:before {
	content: "";
}

.icon-square-o:before {
	content: "";
}

.icon-stability:before {
	content: "";
}

.icon-storage:before {
	content: "";
}

.icon-subscribe:before {
	content: "";
}

.icon-swap-calls:before {
	content: "";
}

.icon-tables:before {
	content: "";
}

.icon-tags:before {
	content: "";
}

.icon-team-collapsed-view:before {
	content: "";
}

.icon-team-expanded-view:before {
	content: "";
}

.icon-terminalalt:before {
	content: "";
}

.icon-text:before {
	content: "";
}

.icon-thumbnail-view:before {
	content: "";
}

.icon-tine:before {
	content: "";
}

.icon-toggle-menu:before {
	content: "";
}

.icon-too-fast:before {
	content: "";
}

.icon-too-slow:before {
	content: "";
}

.icon-tools:before {
	content: "";
}

.icon-touch-gesture:before {
	content: "";
}

.icon-touch-point:before {
	content: "";
}

.icon-touch:before {
	content: "";
}

.icon-transcript:before {
	content: "";
}

.icon-transit:before {
	content: "";
}

.icon-trash:before {
	content: "";
}

.icon-tree-closed:before {
	content: "";
}

.icon-tree-opened:before {
	content: "";
}

.icon-uikit:before {
	content: "";
}

.icon-universal-inbox:before {
	content: "";
}

.icon-unlock:before {
	content: "";
}

.icon-user-queue:before {
	content: "";
}

.icon-user:before {
	content: "";
}

.icon-vibrate:before {
	content: "";
}

.icon-view-feed-16:before {
	content: "";
}

.icon-view-feed-24:before {
	content: "";
}

.icon-view-feed-48:before {
	content: "";
}

.icon-voicemail:before {
	content: "";
}

.icon-waiting-silence:before {
	content: "";
}

.icon-wallpaper:before {
	content: "";
}

.icon-watchlist:before {
	content: "";
}

.icon-web-sharing:before {
	content: "";
}

.icon-webhook:before {
	content: "";
}

.icon-whiteboard-cross:before {
	content: "";
}

.icon-whiteboard:before {
	content: "";
}

.icon-work:before {
	content: "";
}

.icon-zip:before {
	content: "";
}

.icon-active-speaker-cross:before {
	content: "";
}

.icon-active-speaker:before {
	content: "";
}

.icon-audio-broadcast:before {
	content: "";
}

.icon-audio-min:before {
	content: "";
}

.icon-audio-plus:before {
	content: "";
}

.icon-audio-settings:before {
	content: "";
}

.icon-broadcast-message:before {
	content: "";
}

.icon-dual-line:before {
	content: "";
}

.icon-headset-cross:before {
	content: "";
}

.icon-headset:before {
	content: "";
}

.icon-intercom-duplex-connected:before {
	content: "";
}

.icon-intercom-whisper:before {
	content: "";
}

.icon-intercom:before {
	content: "";
}

.icon-line-out-left:before {
	content: "";
}

.icon-line-out-right:before {
	content: "";
}

.icon-locked-speaker:before {
	content: "";
}

.icon-mic-in:before {
	content: "";
}

.icon-microphone:before {
	content: "";
}

.icon-missed-call:before {
	content: "";
}

.icon-music:before {
	content: "";
}

.icon-mute:before {
	content: "";
}

.icon-new-call:before {
	content: "";
}

.icon-off-hook:before {
	content: "";
}

.icon-other-phone:before {
	content: "";
}

.icon-outgoing-call:before {
	content: "";
}

.icon-paired-audio:before {
	content: "";
}

.icon-paired-call:before {
	content: "";
}

.icon-parked:before {
	content: "";
}

.icon-phone-cross:before {
	content: "";
}

.icon-phone:before {
	content: "";
}

.icon-redial:before {
	content: "";
}

.icon-ringer-settings:before {
	content: "";
}

.icon-sound:before {
	content: "";
}

.icon-speaker-cross:before {
	content: "";
}

.icon-speaker-out-left:before {
	content: "";
}

.icon-speaker:before {
	content: "";
}

.icon-volume-cross:before {
	content: "";
}

.icon-volume:before {
	content: "";
}

.icon-ac-power:before {
	content: "";
}

.icon-accesspoint-outline:before {
	content: "";
}

.icon-accesspoint:before {
	content: "";
}

.icon-cpu-chip:before {
	content: "";
}

.icon-dc-power:before {
	content: "";
}

.icon-desk-phone:before {
	content: "";
}

.icon-devices:before {
	content: "";
}

.icon-endpoint:before {
	content: "";
}

.icon-gpu-graphicscard:before {
	content: "";
}

.icon-hfc-node-16:before {
	content: "";
}

.icon-hfc-node-24:before {
	content: "";
}

.icon-hfc-node-48:before {
	content: "";
}

.icon-key-expansion-module:before {
	content: "";
}

.icon-manage-cable:before {
	content: "";
}

.icon-memory-ram:before {
	content: "";
}

.icon-network:before {
	content: "";
}

.icon-pc:before {
	content: "";
}

.icon-power-contain:before {
	content: "";
}

.icon-power:before {
	content: "";
}

.icon-router-outline:before {
	content: "";
}

.icon-router:before {
	content: "";
}

.icon-soft-phone:before {
	content: "";
}

.icon-switch-outline:before {
	content: "";
}

.icon-switch:before {
	content: "";
}

.icon-tablet:before {
	content: "";
}

.icon-virtual-machine:before {
	content: "";
}

.icon-web-camera:before {
	content: "";
}

.icon-wlc-outline:before {
	content: "";
}

.icon-wlc:before {
	content: "";
}

.icon-4-way-move-16:before {
	content: "";
}

.icon-4-way-move-24:before {
	content: "";
}

.icon-4-way-move-48:before {
	content: "";
}

.icon-4-way-nav:before {
	content: "";
}

.icon-arrow-down-tail:before {
	content: "";
}

.icon-arrow-left-tail:before {
	content: "";
}

.icon-arrow-right-tail:before {
	content: "";
}

.icon-arrow-up-tail:before {
	content: "";
}

.icon-arrow:before {
	content: "";
}

.icon-back:before {
	content: "";
}

.icon-call-forward-divert:before {
	content: "";
}

.icon-call-handling:before {
	content: "";
}

.icon-chevron-down:before {
	content: "";
}

.icon-chevron-left-double:before {
	content: "";
}

.icon-chevron-left:before {
	content: "";
}

.icon-chevron-right-circle:before {
	content: "";
}

.icon-chevron-right-double:before {
	content: "";
}

.icon-chevron-right:before {
	content: "";
}

.icon-chevron-up:before {
	content: "";
}

.icon-close-keyboard:before {
	content: "";
}

.icon-cloud-upload:before {
	content: "";
}

.icon-download-contain:before {
	content: "";
}

.icon-download:before {
	content: "";
}

.icon-dropdown:before {
	content: "";
}

.icon-export-16:before {
	content: "";
}

.icon-export-24:before {
	content: "";
}

.icon-export-48:before {
	content: "";
}

.icon-export:before {
	content: "";
}

.icon-fbw:before {
	content: "";
}

.icon-forced-sign-in:before {
	content: "";
}

.icon-forward-to-mobility:before {
	content: "";
}

.icon-general-source-cross:before {
	content: "";
}

.icon-general-source:before {
	content: "";
}

.icon-group-call:before {
	content: "";
}

.icon-import-16:before {
	content: "";
}

.icon-import-24:before {
	content: "";
}

.icon-import-48:before {
	content: "";
}

.icon-import:before {
	content: "";
}

.icon-incoming-call:before {
	content: "";
}

.icon-jump-out:before {
	content: "";
}

.icon-keyboard-close:before {
	content: "";
}

.icon-leave-meeting:before {
	content: "";
}

.icon-left-arrow:before {
	content: "";
}

.icon-maximize:before {
	content: "";
}

.icon-meet-me:before {
	content: "";
}

.icon-merge-call:before {
	content: "";
}

.icon-minimize:before {
	content: "";
}

.icon-move-page:before {
	content: "";
}

.icon-numbered-input:before {
	content: "";
}

.icon-numbered-output:before {
	content: "";
}

.icon-panel-shift-left:before {
	content: "";
}

.icon-panel-shift-right:before {
	content: "";
}

.icon-reply-all:before {
	content: "";
}

.icon-right-arrow-closed-contained:before {
	content: "";
}

.icon-right-arrow-closed-outline:before {
	content: "";
}

.icon-right-arrow-contain:before {
	content: "";
}

.icon-right-arrow-contained:before {
	content: "";
}

.icon-right-arrow-outline:before {
	content: "";
}

.icon-right-arrow:before {
	content: "";
}

.icon-send-email:before {
	content: "";
}

.icon-share-contain:before {
	content: "";
}

.icon-share-content:before {
	content: "";
}

.icon-share:before {
	content: "";
}

.icon-transfer-to-mobile:before {
	content: "";
}

.icon-transfer:before {
	content: "";
}

.icon-upload-contain:before {
	content: "";
}

.icon-upload:before {
	content: "";
}

.icon-whisper:before {
	content: "";
}

.icon-draw:before {
	content: "";
}

.icon-edit-call:before {
	content: "";
}

.icon-edit:before {
	content: "";
}

.icon-eraser:before {
	content: "";
}

.icon-hide-editor:before {
	content: "";
}

.icon-highlight-line:before {
	content: "";
}

.icon-highlight:before {
	content: "";
}

.icon-highlighter-check:before {
	content: "";
}

.icon-highlighter:before {
	content: "";
}

.icon-keywords:before {
	content: "";
}

.icon-link-broken:before {
	content: "";
}

.icon-link:before {
	content: "";
}

.icon-pencil:before {
	content: "";
}

.icon-rotate-object-ccw:before {
	content: "";
}

.icon-rotate-object-cw:before {
	content: "";
}

.icon-save:before {
	content: "";
}

.icon-screen-capture-square:before {
	content: "";
}

.icon-screen-capture:before {
	content: "";
}

.icon-show-editor:before {
	content: "";
}

.icon-sort-amount-asc:before {
	content: "";
}

.icon-sort-amount-desc:before {
	content: "";
}

.icon-text-color:before {
	content: "";
}

.icon-text-format:before {
	content: "";
}

.icon-text-size:before {
	content: "";
}

.icon-zoom-in:before {
	content: "";
}

.icon-zoom-out:before {
	content: "";
}

.icon-file-archive-o:before {
	content: "";
}

.icon-file-audio-o:before {
	content: "";
}

.icon-file-code-o:before {
	content: "";
}

.icon-file-excel-o:before {
	content: "";
}

.icon-file-image-o:before {
	content: "";
}

.icon-file-o:before {
	content: "";
}

.icon-file-pdf-o:before {
	content: "";
}

.icon-file-powerpoint-o:before {
	content: "";
}

.icon-file-text-o:before {
	content: "";
}

.icon-file-text:before {
	content: "";
}

.icon-file-video-o:before {
	content: "";
}

.icon-file-word-o:before {
	content: "";
}

.icon-file:before {
	content: "";
}

.icon-folder:before {
	content: "";
}

.icon-notes:before {
	content: "";
}

.icon-report:before {
	content: "";
}

.icon-spam:before {
	content: "";
}

.icon-syslog:before {
	content: "";
}

.icon-social-facebook:before {
	content: "";
}

.icon-social-linkedin:before {
	content: "";
}

.icon-social-twitter:before {
	content: "";
}

.icon-alarm:before {
	content: "";
}

.icon-alert-blank:before {
	content: "";
}

.icon-alert:before {
	content: "";
}

.icon-alerting:before {
	content: "";
}

.icon-battery-0:before {
	content: "";
}

.icon-battery-25:before {
	content: "";
}

.icon-battery-50:before {
	content: "";
}

.icon-battery-75:before {
	content: "";
}

.icon-battery-full:before {
	content: "";
}

.icon-battery:before {
	content: "";
}

.icon-bell-cross:before {
	content: "";
}

.icon-bell:before {
	content: "";
}

.icon-certified:before {
	content: "";
}

.icon-charging:before {
	content: "";
}

.icon-check-outline:before {
	content: "";
}

.icon-check-square-o:before {
	content: "";
}

.icon-check-square:before {
	content: "";
}

.icon-check:before {
	content: "";
}

.icon-checklist:before {
	content: "";
}

.icon-device-crash:before {
	content: "";
}

.icon-device-faults:before {
	content: "";
}

.icon-device-reload:before {
	content: "";
}

.icon-disc-not-connected:before {
	content: "";
}

.icon-error-outline:before {
	content: "";
}

.icon-error:before {
	content: "";
}

.icon-exclamation-circle:before {
	content: "";
}

.icon-exclamation-triangle:before {
	content: "";
}

.icon-flagged:before {
	content: "";
}

.icon-info-circle:before {
	content: "";
}

.icon-info-outline:before {
	content: "";
}

.icon-info:before {
	content: "";
}

.icon-no-signal:before {
	content: "";
}

.icon-presence-available:before {
	content: "";
}

.icon-presence-end:before {
	content: "";
}

.icon-presence-offline:before {
	content: "";
}

.icon-priority:before {
	content: "";
}

.icon-running-application:before {
	content: "";
}

.icon-sent:before {
	content: "";
}

.icon-spinner:before {
	content: "";
}

.icon-star-empty:before {
	content: "";
}

.icon-star-half:before {
	content: "";
}

.icon-star:before {
	content: "";
}

.icon-warning-outline:before {
	content: "";
}

.icon-warning:before {
	content: "";
}

.icon-aci:before {
	content: "";
}

.icon-bdb:before {
	content: "";
}

.icon-bluetooth-contain-cross:before {
	content: "";
}

.icon-bluetooth-contained:before {
	content: "";
}

.icon-bluetooth-outline:before {
	content: "";
}

.icon-bluetooth:before {
	content: "";
}

.icon-dms:before {
	content: "";
}

.icon-dna:before {
	content: "";
}

.icon-ethernet:before {
	content: "";
}

.icon-extension-mobility:before {
	content: "";
}

.icon-ground:before {
	content: "";
}

.icon-mlpp-1:before {
	content: "";
}

.icon-mlpp-2:before {
	content: "";
}

.icon-mlpp-3:before {
	content: "";
}

.icon-mlpp-4:before {
	content: "";
}

.icon-mlpp-5:before {
	content: "";
}

.icon-sd:before {
	content: "";
}

.icon-spark:before {
	content: "";
}

.icon-usb:before {
	content: "";
}

.icon-webex-meetings-16:before {
	content: "";
}

.icon-webex-meetings-24:before {
	content: "";
}

.icon-webex-meetings-48:before {
	content: "";
}

.icon-webex-teams-16:before {
	content: "";
}

.icon-webex-teams-24:before {
	content: "";
}

.icon-webex-teams-48:before {
	content: "";
}

.icon-webex:before {
	content: "";
}

.icon-wifi-1:before {
	content: "";
}

.icon-wifi-2:before {
	content: "";
}

.icon-wifi-3:before {
	content: "";
}

.icon-wifi:before {
	content: "";
}

.icon-calendar-meetings:before {
	content: "";
}

.icon-calendar-weekly:before {
	content: "";
}

.icon-calendar:before {
	content: "";
}

.icon-clock:before {
	content: "";
}

.icon-day:before {
	content: "";
}

.icon-exernal-calendar:before {
	content: "";
}

.icon-history:before {
	content: "";
}

.icon-month:before {
	content: "";
}

.icon-time:before {
	content: "";
}

.icon-timeline:before {
	content: "";
}

.icon-aux-camera:before {
	content: "";
}

.icon-brightness:before {
	content: "";
}

.icon-document-camera-cross:before {
	content: "";
}

.icon-document-camera:before {
	content: "";
}

.icon-ffw:before {
	content: "";
}

.icon-image-contain:before {
	content: "";
}

.icon-image:before {
	content: "";
}

.icon-laser-pointer:before {
	content: "";
}

.icon-media-viewer:before {
	content: "";
}

.icon-multi-display:before {
	content: "";
}

.icon-picture-in-picture:before {
	content: "";
}

.icon-pip-0:before {
	content: "";
}

.icon-pip-1:before {
	content: "";
}

.icon-pip-2:before {
	content: "";
}

.icon-pip-3:before {
	content: "";
}

.icon-pip:before {
	content: "";
}

.icon-play-contained:before {
	content: "";
}

.icon-play:before {
	content: "";
}

.icon-presentation:before {
	content: "";
}

.icon-record:before {
	content: "";
}

.icon-self-view-alt:before {
	content: "";
}

.icon-self-view-crossed:before {
	content: "";
}

.icon-self-view:before {
	content: "";
}

.icon-skip-bw:before {
	content: "";
}

.icon-skip-fw:before {
	content: "";
}

.icon-step-backward:before {
	content: "";
}

.icon-step-forward:before {
	content: "";
}

.icon-step-next:before {
	content: "";
}

.icon-step-prev:before {
	content: "";
}

.icon-stop:before {
	content: "";
}

.icon-swap-camera:before {
	content: "";
}

.icon-swap-video-camera:before {
	content: "";
}

.icon-video-cross:before {
	content: "";
}

.icon-video-input:before {
	content: "";
}

.icon-video-layout:before {
	content: "";
}

.icon-video-settings:before {
	content: "";
}

.icon-video-tips:before {
	content: "";
}

.icon-video:before {
	content: "";
}

.icon-view-feed-dual:before {
	content: "";
}

.icon-view-feed-multi:before {
	content: "";
}

.icon-view-feed-single:before {
	content: "";
}

.icon-view-preview-telepresence:before {
	content: "";
}

.icon-view-side-by-side:before {
	content: "";
}

.icon-view-split:before {
	content: "";
}

.icon-view-stacked:before {
	content: "";
}

.icon-small:before {
    font-size: 1.8rem !important;
}