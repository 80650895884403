.modal-backdrop {
    z-index: 2 !important;
}

.modal {
    opacity: 1 !important;
    background: rgba(0, 0, 0, .32) !important;
    z-index: 3 !important;
    overflow-y: hidden !important;
}

.modal-dialog {
    margin-top: 4% !important;
}

.modal-header {
    background: #1e4471 !important;
    color: #ffffff !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    height: 50px !important;
    padding: 0rem 1rem !important;
}

.modal-title {
    font-size: 24px !important;
    text-align: left;
    font-weight: 200 !important;
    line-height: 2 !important;
    color: #ffffff !important;
}

.modal-body {
    height: 65vh !important;
    overflow-y: auto !important;
}

.modal-content {
    border-radius: 10px !important;
}

.alertModalWindowClass {
    background: none !important;
}

.alertModalWindowClass .modal-content {
    border: none !important;
}

.modal-body::-webkit-scrollbar {
    background-color: #f2f2f2;
    border-radius: 10px;
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
}

.modal-body::-webkit-scrollbar-track {
    border-radius: 10px;
}

.modal-body::-webkit-scrollbar-thumb:active {
    background-color: #b5b1b1;
}

.modal-body::-webkit-scrollbar-thumb:hover {
    background-color: #b5b1b1;
}