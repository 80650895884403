div.row {
    width: 100% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
}

[class*="col-"] {
    margin-left: 0% !important;
    margin-right: 0% !important;
    padding-left: 0% !important;
    padding-right: 0% !important;
}

h1,h2,h3,h4,h5,h6 {
    margin-top: 0% !important;
    margin-bottom: 0% !important;
    font-family: inherit !important;
    line-height: 1.2 !important;
    font-family: CiscoSans,Arial,sans-serif !important;
}

a {
    color: #017cad !important;
    text-decoration: none !important;
}