.grid-renderer-edit-icon {
    color: #00a3d2 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-edit-icon.disable {
    color: #00bceb !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-save-icon {
    color: #00a3d2 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.icon-prevent-download-contain {
    color: #c90a01 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-close-icon {
    color: #c90a01 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-archive-icon {
    color: #00a3d2 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-archive-icon.disable {
    color: #00bceb !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-delete-icon {
    color: #c90a01 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-delete-icon.disable {
    color: #e2231a !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-download-icon {
    color: #017cad !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-activate-icon {
    color: #017cad !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-deactivate-icon {
    color: #e2231a !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-publish-icon {
    color: #51a632 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-workflow-icon {
    color: #fbab18 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-republish-icon {
    color: #00bceb !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-publish-icon.disable {
    color: #84d965 !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-anchor {
    white-space: nowrap !important;
    width: 98% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline-block !important;
    color: #017cad !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    text-decoration: none !important;
}

.grid-renderer-reminder-icon {
    color: #eed202 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.ag-icon-tree-closed {
    font-family: var(--ag-icon-font-family-tree-closed, var(--ag-icon-font-family)) !important;
    font-weight: var(--ag-icon-font-weight-tree-closed, var(--ag-icon-font-weight)) !important;
}

.ag-icon-tree-open {
    font-family: var(--ag-icon-font-family-tree-open, var(--ag-icon-font-family)) !important;
    font-weight: var(--ag-icon-font-weight-tree-open, var(--ag-icon-font-weight)) !important;
}


.grid-renderer-reminder-icon.disable {
    color: #eed202b8 !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-restore-icon {
    color: #00a3d2 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-visibility-icon {
    color: #00a3d2 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-visibility-icon.disable {
    color: #00bceb !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.ag-icon {
    font-family: 'agGridBalham' !important;
    font-weight: normal !important;
    color: #00000080 !important;
    font-size: 16px !important;
    line-height: 16px;
    font-style: normal !important;
    font-variant: normal;
    text-transform: none !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    width: 16px;
    height: 16px;
    position: relative !important;
}

.grid-renderer-save-icon {
    color: #00a3d2 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}

.grid-renderer-notes-icon {
    color: #00a3d2 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-size: 20px !important;
    padding: 0% 1% !important;
}